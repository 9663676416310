import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NewCustomerDialog } from '../customers-leads/customers-leads.component';


export interface PeriodicElement {
  date: string;
  type: string;
  no:string;
  customer: string;
  memo:string,
  duedate: string,
  balance:string,
  total:string,
  status: string
}


@Component({
  selector: 'app-all-sales',
  templateUrl: './all-sales.component.html',
  styleUrls: ['./all-sales.component.scss']
})

export class AllSalesComponent implements OnInit {

  filterMenu
  

  public ELEMENT_DATA: PeriodicElement[] = [
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
    {date: '05/19/2022', type: 'Invoice', no: '2022_04BK_4', customer: 'Kforce Inc.', memo:'2022_04BK_4', duedate:'05/19/2022', balance:'$3200.00', total:'$3200.00', status:'Open'},
  ];

  displayedColumns: string[] = ['date', 'type', 'no', 'customer', 'memo', 'duedate', 'balance', 'total', 'status', 'action'];
  dataSource!: MatTableDataSource<PeriodicElement>;


  constructor(public dialog:MatDialog) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    // window.addEventListener("click", () => {
    //   this.filterMenu = this.filterMenu === true ? false : false;
    //  });
  }

 
  newCustomer(): void {
    const dialogRef = this.dialog.open(NewCustomerDialog, {
      width: '250px',
      panelClass: 'new-customer'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  showFilter(){
    this.filterMenu = !this.filterMenu;
  }

  onClickOutside(ev){
console.log(ev)
if(ev && this.filterMenu){
  this.filterMenu = false;
}
  }


}
