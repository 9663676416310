import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{DashboardComponent} from 'src/pages/dashboard/dashboard.component';
import {VendorProfileComponent} from 'src/pages/vendor-profile/vendor-profile.component'
import { AccountPaybleComponent } from 'src/pages/account-payble/account-payble.component';
import { DocumentComponent } from 'src/pages/document/document.component';
import { EmployeeContractComponent } from 'src/pages/employee-contract/employee-contract.component';
import { TimeSheetComponent } from 'src/pages/time-sheet/time-sheet.component';
import { VendorInvoiceComponent } from 'src/pages/vendor-invoice/vendor-invoice.component';
import { LoginComponent } from 'src/pages/login/login.component';
import { SignupComponent } from 'src/pages/signup/signup.component';
import { CustomerProfileComponent } from 'src/pages/customer-profile/customer-profile.component';
import { ProfileComponent } from 'src/pages/profile/profile.component';
import { HomeComponent } from 'src/pages/home/home.component';
import { SalesComponent } from 'src/pages/sales/sales.component';



const routes: Routes = [
  {path: '', redirectTo:'login', pathMatch:'full' },
  {path: 'login', component: LoginComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'dashboard', component: DashboardComponent,
  
  children:[
    {path: '', redirectTo:'vendor-profile', pathMatch:'full' },
    {path: 'vendor-profile', component: VendorProfileComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'customer-profile', component: CustomerProfileComponent},
    {path: 'account-payable', component: AccountPaybleComponent},
    {path: 'sales', component: SalesComponent},
    {path: 'contacts', component: DocumentComponent},
    {path: 'employee-contract', component: EmployeeContractComponent},
    {path: 'time-sheet', component: TimeSheetComponent},
    {path: 'vendor-invoice', component: VendorInvoiceComponent},
    {path: 'home', component: HomeComponent}

  ]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
