import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import {Router} from '@angular/router'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm:FormGroup;

  constructor(public router:Router, public formBuilder:FormBuilder, public matSnackBar:MatSnackBar) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username:['', Validators.required],
      password:['',Validators.required]
    })
  }

  login (){
    if(this.loginForm.valid){
      this.router.navigate(['dashboard/home'])

    }else{
      this.matSnackBar.open('Please enter all fields', 'OK', {
        verticalPosition:'top',
        duration:2000,
        panelClass:'snack-error'
      })
    }
  }

}
