import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  autocompleteItems =[
    {Title: 'Volvo S60 2019'},
    {Title: 'BMW 8 Series'},
    {Title: 'Skoda Superb 2019'},
    {Title: 'Audi e-tron'},
    {Title: 'Jaguar XE 2019'},
    {Title: 'MG RX5'},
    {Title: 'Porsche Cayenne Coupe'},
    {Title: 'Land Rover Range Rover Evoque 2019'},
    {Title: 'Hyundai Verna 2020'},
    {Title: 'Toyota Vellfire'}
  ]

  constructor() { }

  ngOnInit() {
  }

}
