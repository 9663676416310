import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import 'hammerjs';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule,MatAutocompleteModule, MatTooltipModule, MatTabsModule, 
  MatSelectModule, MatIconModule,MatFormFieldModule,MatExpansionModule,
  MatInputModule,MatMenuModule,MatChipsModule,MatSidenavModule,
  MatDialogModule,MatProgressBarModule,MatTableModule,MatRadioModule
,MatCheckboxModule,MatDatepickerModule, MatSnackBarModule, MatSlideToggleModule} from '@angular/material';

import { MatMomentDateModule } from '@angular/material-moment-adapter';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import { HomeComponent } from '../pages/home/home.component';
import { VendorProfileComponent } from '../pages/vendor-profile/vendor-profile.component';
import { HeaderComponent } from '../pages/header/header.component';
import { AccountPaybleComponent } from '../pages/account-payble/account-payble.component';
import { DocumentComponent } from '../pages/document/document.component';
import { EmployeeContractComponent } from 'src/pages/employee-contract/employee-contract.component';
import { TimeSheetComponent } from '../pages/time-sheet/time-sheet.component';
import { VendorInvoiceComponent } from '../pages/vendor-invoice/vendor-invoice.component';
import { LoginComponent } from '../pages/login/login.component';
import { SignupComponent } from '../pages/signup/signup.component';
import { CustomerProfileComponent } from '../pages/customer-profile/customer-profile.component';
import { FooterComponent } from '../pages/footer/footer.component';
import { ProfileComponent } from '../pages/profile/profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SalesComponent } from '../pages/sales/sales.component';
import { OverviewComponent } from '../pages/components/overview/overview.component';
import { PaymentComponent } from 'src/pages/components/payment/payment.component';
import { AllSalesComponent } from 'src/pages/components/all-sales/all-sales.component';
import { InvoicesComponent, NewInvoiceDialog } from 'src/pages/components/invoices/invoices.component';
import { ProductComponent } from 'src/pages/components/product/product.component';
import { CustomersComponent } from 'src/pages/components/customers/customers.component';
import { MarketingComponent } from 'src/pages/components/marketing/marketing.component';
import { CustomersLeadsComponent, NewCustomerDialog } from 'src/pages/components/customers-leads/customers-leads.component';
import { ClickOutsideDirective } from './click-outside.directive';



@NgModule({
  declarations: [
    AppComponent,
    PaymentComponent,
    AllSalesComponent,
    InvoicesComponent,
    ProductComponent,
    CustomersComponent,

    DashboardComponent,
    HomeComponent,
    VendorProfileComponent,
    HeaderComponent,
    AccountPaybleComponent,
    DocumentComponent,
    EmployeeContractComponent,
    TimeSheetComponent,
    VendorInvoiceComponent,
    LoginComponent,
    SignupComponent,
    CustomerProfileComponent,
    FooterComponent,
    ProfileComponent,
    SalesComponent,
    OverviewComponent,
    NewInvoiceDialog,
    MarketingComponent,
    CustomersLeadsComponent,
    NewCustomerDialog,
    ClickOutsideDirective

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatAutocompleteModule, 
    MatTooltipModule, 
    MatTabsModule, 
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatInputModule,
    MatMenuModule,
    MatChipsModule,
    MatSidenavModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTableModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSlideToggleModule
    
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [NewInvoiceDialog, NewCustomerDialog]
})
export class AppModule { }
