import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  sidemenuArray = [
    {title:'Dashboard', path:'home', img:'assets/img/user11.png'},
    {title:'Profile', path:'profile', img:'assets/img/menu1.png'},
    {title:'Accounts', path:'', img:'assets/img/menu2.png'},
    {title:'Account Payable', path:'account-payable', img:'assets/img/menu2.png'},
    {title:'Customer Profiles ', path:'customer-profile', img:'assets/img/menu3.png'},
    {title:'Sales ', path:'sales', img:'assets/img/menu3.png'},
    {title:'Vendor Profiles', path:'vendor-profile', img:'assets/img/menu4.png'},
    {title:'Contacts', path:'contacts', img:'assets/img/menu5.png'},
    {title:'Time Sheet', path:'time-sheet', img:'assets/img/menu7.png'},
    {title:'Employee  Contract', path:'employee-contract', img:'assets/img/menu8.png'},
    {title:'Vendor Invoice', path:'vendor-invoice', img:'assets/img/menu9.png'},
    
  ];

  submenu= {title:'', path:'', img:''};
  urlArr;

  activePage;

  constructor(public router:Router) { }

  ngOnInit() {
    this.urlArr = document.URL.split('/');
if(this.urlArr[this.urlArr.length-1] != 'userdb'){
  this.activePage = this.urlArr[this.urlArr.length-1];
 
  this.sidemenuArray.forEach(sidemnu=>{
 
      if(sidemnu.path == this.activePage){
        this.submenu = sidemnu;
      }
  
  })
}else{
  this.submenu = this.sidemenuArray[0];

}
  }
  gotopath(sidemenu){
    this.submenu = sidemenu;
this.router.navigate(['/dashboard/'+sidemenu.path])
  }

}
