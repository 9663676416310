import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm:FormGroup

  constructor(public router:Router, public formBuilder:FormBuilder, public matSnackBar:MatSnackBar) { }

  ngOnInit() {
    this.signupForm = this.formBuilder.group({
      firstname:['',Validators.required],
      lastname:['',Validators.required],
      emailid:['',Validators.required],
      password:['',Validators.required],
      visa_type:['',Validators.required],
      entitlement_rate:['',Validators.required],
      status:['',Validators.required],
      work_email:['',Validators.required],
      address:['',Validators.required]
    })
  }

  
  login (){
    if(this.signupForm.valid){
      this.router.navigate(['dashboard/home'])

    }else{
      this.matSnackBar.open('Please enter all fields', 'OK', {
        verticalPosition:'top',
        duration:2000,
        panelClass:'snack-error'
      })
    }
  }

}
