import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'


export interface PeriodicElement {
  date: string;
  no:string;
  customer: string;
  amount:string,
  status: string
}


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  public ELEMENT_DATA: PeriodicElement[] = [
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
    {date: '05/19/2022', no: '2022_04BK_4', customer: 'Kforce Inc.', amount:'2022_04BK_4',  status:'Overdue on 12/21/2021'},
  ];
  
  displayedColumns: string[] = ['date', 'no', 'customer', 'amount', 'status', 'action'];
  dataSource!: MatTableDataSource<PeriodicElement>;

  constructor(public dialog: MatDialog) { }

  createNewInvoice(): void {
    const dialogRef = this.dialog.open(NewInvoiceDialog, {
      width: '250px',
      panelClass: 'new-invoice'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }
}


@Component({
  selector: 'invoice-dialog',
  templateUrl: 'invoice.html',
})

export class NewInvoiceDialog {

  constructor(
    public dialogRef: MatDialogRef<NewInvoiceDialog>,) {}

    onNoClick(): void {
      this.dialogRef.close();
    }

}
