import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss']
})
export class CustomerProfileComponent implements OnInit {

  constructor() {}

  ngOnInit() {

  }

  tabVal= 'customersLead'

  changeTab(val){
    this.tabVal = val;
  }
}
