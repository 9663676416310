import { Component, OnInit } from '@angular/core';
declare var Chart:any;
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  linechart;
  constructor() { }

  ngOnInit() {
    this.linechart = new Chart('myChart', {
      type: 'line',
      data: {
          labels: ['1', '6', '7', '10', '13', '16', '19', '22', '25'],
          datasets: [{
              label: '# of Votes',
              data: ['20000', '15000', '25000', '20000', '15000', '25000', '20000', '15000', '25000'],
              backgroundColor: [
                  '#0085ff2b'
              ],
              borderColor: [
                  '#0085ff'
              ],
             
              borderWidth: 1,
              borderCapStyle: 'round',
              lineTension: 0.4,        
              radius: 6,
              borderJoinStyle: 'round',
              fill: true
          }]
      },
      options: {
        responsive: true,
        plugins:{
          legend:{
            display: false
          },
          borderJoinStyle: 'round',
          tension: 3,
          bezierCurve : false
        }
         
      }
  });
  }

}
