import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


export interface PeriodicElement {
  Customer : string;
  phone: string;
  Open_balance:string;
}

@Component({
  selector: 'app-customers-leads',
  templateUrl: './customers-leads.component.html',
  styleUrls: ['./customers-leads.component.scss']
})
export class CustomersLeadsComponent implements OnInit {

  
  public ELEMENT_DATA: PeriodicElement[] = [
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
    {Customer: '77 Miltown Rd, C-5, East Brunswick NJ, 08816', phone: '(847)264-8595', Open_balance: '$550.00'},
  ];

  displayedColumns: string[] = ['Customer', 'phone', 'Open_balance', 'action'];
  dataSource!: MatTableDataSource<PeriodicElement>;


  constructor(public dialog: MatDialog) { }

  newCustomer(): void {
    const dialogRef = this.dialog.open(NewCustomerDialog, {
      width: '250px',
      panelClass: 'new-customer'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  }


}


@Component({
  selector: 'new-customer-dialog',
  templateUrl: 'new-customer.html',
})

export class NewCustomerDialog {

  constructor(
    public dialogRef: MatDialogRef<NewCustomerDialog>,) {}

    onNoClick(): void {
      this.dialogRef.close();
    }

}

